import * as CryptoJS from "crypto-js";

export class NonceManager {

    static NONCE_KEY = "rawNonce";

    static encryptNonce(rawNonce: string): string {
        return CryptoJS.SHA256(rawNonce).toString(CryptoJS.enc.Hex);
    }

    static setNonceToStorage(rawNonce: string): void {
        window.localStorage.setItem(this.NONCE_KEY, rawNonce);
    }

    static getNonceFromStorage(): string|null {
        return window.localStorage.getItem(this.NONCE_KEY);
    }
}
